/* eslint-disable import/first */

import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;
import React from "react";
import { useMutation, useQueryClient } from "react-query";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import spectraLogo from "../static/img/Spectra Plasmonics logo.webp";
import { useUserLocations, useUserSettings, useUserId } from "../api/user";

//Elements and CSS from AntDesign
import "antd/dist/antd.min.css";

import "../styles/Navbar.css";
// import Login from './Login'

const Navbar = ({ authenticated }) => {
  const { data: locations } = useUserLocations();
  const { data: settings } = useUserSettings();
  const {
    data: userId,
    isLoading: userIdIsLoading,
    isError: userIdIsError,
  } = useUserId();
  const queryClient = useQueryClient();

  const setLocation = useMutation(
    (location) =>
      axios.post("/user/post-location", {
        userId: userId,
        locationId: location,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["settings"]);
        // This will refetch every query with "byLoc" at the start of the key
        queryClient.refetchQueries("byLoc");
      },
    }
  );

  async function handleLocationChange(e) {
    setLocation.mutate(e.target.value, {
      onError: (error) => {
        console.log(error);
      },
    });
  }

  return (
    <nav
      id='mainNav'
      className='navbar navbar-expand-lg navbar-light bg-light shadow fixed-top'
      style={{ borderBottom: "0.3rem solid #e3803b" }}
    >
      <a className='navbar-brand' href='/'>
        <img src={spectraLogo} style={{ maxWidth: "20rem" }} />
      </a>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarResponsive'
        aria-controls='navbarResponsive'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon'></span>
      </button>
      <div className='collapse navbar-collapse' id='navbarResponsive'>
        {userIdIsLoading && (
          <div className='spinner-border' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        )}
        {userIdIsError && (
          <ul className='navbar-nav ml-auto'>
            <li className='nav-item'>
              <a className='nav-link' href='/login'>
                Log in
              </a>
            </li>
          </ul>
        )}
        {!userIdIsLoading && !userIdIsError && (
          <ul className='navbar-nav ml-auto'>
            <li className='nav-item d-flex'>
              <div className='form-group my-auto mr-2'>
                {locations ? (
                  locations.length > 1 ? (
                    <select
                      className='form-control'
                      style={{ minWidth: "10em" }}
                      value={settings ? settings.currentLoc : ""}
                      onChange={handleLocationChange}
                    >
                      {locations.map((loc, index) => (
                        <option key={index} value={loc.locationId}>
                          {loc.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    locations[0].name
                  )
                ) : (
                  "None"
                )}
              </div>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='/location'>
                Dashboard
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='/data-table'>
                Data Table
              </a>
            </li>
          </ul>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
